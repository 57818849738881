import React from "react";
import {
  Drawer,
  Typography,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import clsx from "clsx";
import { useSelector } from "react-redux";

import user from "../../../../assets/user.png";
import history from "../../../../services/history";

import organization from "../../../../assets/icons/organization.svg";
import organizationWhite from "../../../../assets/icons/organizationWhite.svg";
import organizationBlack from "../../../../assets/icons/organizationBlack.svg";

import teamIcon from "../../../../assets/icons/team.svg";
import teamWhite from "../../../../assets/icons/teamWhite.svg";
import teamBlack from "../../../../assets/icons/teamBlack.svg";

import task from "../../../../assets/icons/task.svg";
import taskWhite from "../../../../assets/icons/taskWhite.svg";
import taskBlack from "../../../../assets/icons/taskBlack.svg";

import points from "../../../../assets/icons/points.svg";
import pointsBlack from "../../../../assets/icons/pointsBlack.svg";

import figures from "../../../../assets/icons/figures.svg";
import figuresWhite from "../../../../assets/icons/figuresWhite.svg";
import figuresBlack from "../../../../assets/icons/figuresBlack.svg";

import Gravatar from "react-gravatar";

import useStyles from "./styles";
import { toast } from "react-toastify";

export default function Sidebar({ open, trogle, match }) {
  const me = useSelector((state) => state.user.me);
  const team = useSelector((state) => state.team.team);
  const classes = useStyles();

  function selected(url) {
    return window.location.href.indexOf(url) !== -1;
  }

  const openTasks = () => {
    if (!team) {
      return toast.error(
        "Você deve ter uma equipe para visualizar as tarefas!"
      );
    }

    history.push("/desafios-joti");
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar} />

      {open && (
        <>
          <List style={{ padding: 0 }}>
            <ListItem className={classes.profileItem}>
              <Gravatar
                email={me?.email}
                className={classes.profileImg}
                size={150}
              />

              <Typography className={classes.name} noWrap>
                {me && me.name}
              </Typography>
              {me && me.team && me.team.level ? (
                <Typography className={classes.level}>
                  {me.team.level}
                </Typography>
              ) : (
                <Typography className={classes.level}>Sem time</Typography>
              )}
            </ListItem>
          </List>

          <List className={classes.listClose}>
            <ListItem
              className={classes.itemOpen}
              button
              onClick={() => history.push("dashboard")}
            >
              <ListItemIcon>
                <img src={selected("dashboard") ? teamBlack : teamIcon} />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  className={
                    selected("dashboard") ? classes.selected : classes.normal
                  }
                >
                  Minha Equipe
                </Typography>
              </ListItemText>
            </ListItem>

            {(!team || team?.type !== "Jota") && (
              <ListItem className={classes.itemOpen} button onClick={openTasks}>
                <ListItemIcon>
                  <img src={selected("desafios-joti") ? taskBlack : task} />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    className={
                      selected("desafios-joti")
                        ? classes.selected
                        : classes.normal
                    }
                  >
                    Desafios
                  </Typography>
                </ListItemText>
              </ListItem>
            )}

            {/*  <ListItem
              className={classes.itemOpen}
              button
              onClick={() => history.push("desafios-jota")}
            >
              <ListItemIcon>
                <img src={selected("desafios-jota") ? taskBlack : task} />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  className={
                    selected("desafios-jota")
                      ? classes.selected
                      : classes.normal
                  }
                >
                  Desafios Rádio
                </Typography>
              </ListItemText>
            </ListItem>
 */}
            {/* <ListItem
              className={classes.itemOpen}
              button
              onClick={() => history.push("certificate")}
            >
              <ListItemIcon>
                <img
                  src={selected("certificate") ? points : pointsBlack}
                  style={{ width: 35 }}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  className={
                    selected("certificate") ? classes.selected : classes.normal
                  }
                >
                  Certificados
                </Typography>
              </ListItemText>
            </ListItem>
 */}
            <ListItem
              className={classes.itemOpen}
              button
              onClick={() => history.push("teams")}
            >
              <ListItemIcon>
                <img src={selected("teams") ? teamBlack : teamIcon} />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  className={
                    selected("teams") ? classes.selected : classes.normal
                  }
                >
                  Equipes
                </Typography>
              </ListItemText>
            </ListItem>

            {/* 
            <ListItem
              className={classes.itemOpen}
              button
              onClick={() => history.push("organization")}
            >
              <ListItemIcon>
                <img
                  src={
                    selected("organization") ? organizationBlack : organization
                  }
                />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  className={
                    selected("organization") ? classes.selected : classes.normal
                  }
                >
                  Organização JOTI
                </Typography>
              </ListItemText>
            </ListItem>
 */}
            {/*      <ListItem
              className={classes.itemOpen}
              button
              onClick={() => history.push("/insignia-remember")}
            >
              <ListItemIcon>
                <img
                  style={{ height: 20, width: 20 }}
                  src={selected("insignia-remember") ? figuresBlack : figures}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  className={
                    selected("insignia-remember")
                      ? classes.selected
                      : classes.normal
                  }
                >
                  Insígnia Remember
                </Typography>
              </ListItemText>
            </ListItem> */}

            {/*  <ListItem
              className={classes.itemOpen}
              button
              onClick={() => history.push("/points")}
            >
              <ListItemIcon>
                <img
                  src={selected("points") ? organizationBlack : organization}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  className={
                    selected("points") ? classes.selected : classes.normal
                  }
                >
                  Pontuação
                </Typography>
              </ListItemText>
            </ListItem>
            */}
          </List>

          <List style={{}}>
            <ListItem>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <a
                    className={classes.sup}
                    href="https://www.escoteiros.org.br/jota-joti/"
                    target="_blank"
                  >
                    Boletim
                  </a>

                  {/*  <a
                    className={classes.sup}
                    href="https://suporte.jotajoti.com.br/"
                    target="_blank"
                  >
                    Suporte
                  </a> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <a
                    className={classes.sup}
                    href="https://discord.gg/HBtswBrpa7"
                    target="_blank"
                  >
                    Discord
                  </a>
                  <a
                    className={classes.sup}
                    href="https://stats.uptimerobot.com/RL6R9uGEw9"
                    target="_blank"
                  >
                    Status
                  </a>
                </div>
              </div>
            </ListItem>
            <ListItem>
              <Button
                className={clsx(classes.button, classes.centerButton)}
                onClick={() => trogle()}
              >
                <ChevronLeftIcon style={{ fontSize: 30, color: "#fff" }} />
                <Typography className={classes.buttonText}>
                  Encolher menu
                </Typography>
              </Button>
            </ListItem>
          </List>
        </>
      )}

      {!open && (
        <>
          <List className={classes.listClose}>
            <ListItem
              button
              className={clsx(classes.profileClose, classes.purpleClose1)}
            >
              <Gravatar
                email={me?.email}
                className={classes.center}
                style={{ borderRadius: 30 }}
                size={150}
              />
            </ListItem>

            {selected("dashboard") && (
              <ListItem
                button
                className={clsx(
                  classes.profileClose,
                  classes.purpleClose2,
                  classes.openTab
                )}
              >
                <img src={teamWhite} className={classes.center} />
              </ListItem>
            )}

            <ListItem
              button
              onClick={() => history.push("/dashboard")}
              className={clsx(classes.profileClose, classes.purpleClose2)}
            >
              <img src={teamWhite} className={classes.center} />
            </ListItem>

            {selected("desafios") && (
              <ListItem
                button
                className={clsx(
                  classes.profileClose,
                  classes.purpleClose3,
                  classes.openTab
                )}
              >
                <img src={taskWhite} className={classes.center} />
              </ListItem>
            )}

            <ListItem
              button
              onClick={openTasks}
              className={clsx(classes.profileClose, classes.purpleClose3)}
            >
              <img src={taskWhite} className={classes.center} />
            </ListItem>

            {/*     {selected("organization") && (
              <ListItem
                button
                className={clsx(
                  classes.profileClose,
                  classes.purpleClose4,
                  classes.openTab
                )}
              >
                <img src={organizationWhite} className={classes.center} />
              </ListItem>
            )}
 */}
            {/*  <ListItem
              button
              onClick={() => history.push("/organization")}
              className={clsx(classes.profileClose, classes.purpleClose4)}
            >
              <img src={organizationWhite} className={classes.center} />
            </ListItem> */}

            {/*  {selected("insignia-remember") && (
              <ListItem
                button
                className={clsx(
                  classes.profileClose,
                  classes.purpleClose4,
                  classes.openTab
                )}
              >
                <img src={figuresWhite} className={classes.center} />
              </ListItem>
            )} */}

            {/*   <ListItem
              button
              onClick={() => history.push("/insignia-remember")}
              className={clsx(classes.profileClose, classes.purpleClose4)}
            >
              <img src={figuresWhite} className={classes.center} />
            </ListItem> */}

            {/*  {selected('points') && (
              <ListItem
                button
                className={clsx(
                  classes.profileClose,
                  classes.purpleClose4,
                  classes.openTab
                )}
              >
                <img src={organizationWhite} className={classes.center} />
              </ListItem>
            )}

            <ListItem
              button
              onClick={() => history.push("/points")}
              className={clsx(classes.profileClose, classes.purpleClose4)}
            >
              <img src={organizationWhite} className={classes.center} />
            </ListItem>
            */}
          </List>

          <List style={{ padding: 0 }}>
            <ListItem
              button
              style={{ backgroundColor: "#664ACF" }}
              onClick={trogle}
            >
              <ListItemIcon>
                <ChevronRightIcon style={{ fontSize: 40, color: "#fff" }} />
              </ListItemIcon>
            </ListItem>
          </List>
        </>
      )}
    </Drawer>
  );
}
