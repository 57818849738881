import React from "react";
import { useSelector } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Hidden, Grid, Container, Typography, Link } from "@material-ui/core";
import farb from "../../../assets/icons/horizontal-invertida.png";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";

import Lgpd from "../../../components/Lgpd";
import Curso1 from "../../../components/Curso1";

import Header from "./Header";
import Drawer from "./Sidebar";
import MobileSidebar from "./MobileSidebar";

import useStyles from "./styles";

import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

function DefaultLayout({ children, match, width }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(
    isWidthDown("md", width) ? false : true
  );

  const { pathname } = useLocation();

  ReactGA.send({ hitType: "pageview", page: pathname });

  return (
    <Grid container style={{ height: "100%" }}>
      <Grid item xs={12} style={{ flex: 0 }}>
        <CssBaseline />
        <Header open={open} trogle={() => setOpen(!open)} />
      </Grid>

      <Hidden md mdUp>
        <MobileSidebar
          open={open}
          trogle={() => setOpen(!open)}
          match={match}
        />
      </Hidden>

      <Grid item xs={12} className={classes.padding}>
        <Grid container style={{ minHeight: "100%" }}>
          <Hidden sm smDown>
            <Grid item open={{ width: 300 }}>
              <Drawer open={open} trogle={() => setOpen(!open)} match={match} />
            </Grid>
          </Hidden>

          <Grid item style={{ flex: 1 }} className={classes.content}>
            <Container
              maxWidth="lg"
              className={classes.padding}
              style={{ height: "100%" }}
            >
              <div style={{ height: "100%", paddingTop: 64 }}>
                {children}

                <Link
                  className={classes.copyright}
                  color="inherit"
                  href="https://devbrasil.com.br"
                  target="_blank"
                >
                  Desenvolvido por
                  <img src={farb} style={{ maxWidth: 65, margin: "0px 5px" }} />
                  {new Date().getFullYear()}
                </Link>
              </div>
            </Container>
          </Grid>
        </Grid>
      </Grid>

      <Lgpd />
      <Curso1 />
    </Grid>
  );
}

export default withWidth()(DefaultLayout);
